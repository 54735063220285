import { Observable } from "rxjs";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import {
  Auth,
  User,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "@angular/fire/auth";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  auth = inject(Auth);
  private _snackBar = inject(MatSnackBar);
  router = inject(Router);

  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  user: User;
  uid: string;
  email: string;
  error: string;

  authState: any = null;

  data$: Observable<any>;

  restoUID: any;

  constructor() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.authState = user;
        localStorage.setItem("user", JSON.stringify(this.authState));
        JSON.parse(localStorage.getItem("user"));
        return this.uid;
      } else {
        localStorage.setItem("user", null);
        JSON.parse(localStorage.getItem("user"));
        return null;
      }
    });
  }

  showError() {
    const ref = this._snackBar.open(
      "Identifiant ou mot de passe incorrect",
      "",
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
        panelClass: "errorSnack",
      }
    );
  }

  emailCurrentUser() {
    this.email = this.authState.email;
    return this.email;
  }

  get isAuthenticated(): boolean {
    return this.authState !== null;
  }

  get currentUserId(): string {
    return this.authState.uid ? this.authState.uid : null;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null ? true : false;
  }

  // Admin

  async loginAdmin(email: string, password: string) {
    return await signInWithEmailAndPassword(this.auth, email, password)
      .then(() => {
        this.router.navigate(["dashboard"]);
      })
      .catch((error) => {
        this.showError();
      });
  }

  async createRestoUser(email, password) {
    return await createUserWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        const uid = result.user.uid;
      })
      .catch((error) => {});
  }

  async logOutAdmin() {
    this.router.navigate(["admin"]);
    return await signOut(this.auth);
  }

  // Resto

  async loginResto(email: string, password: string) {
    return await signInWithEmailAndPassword(this.auth, email, password)
      .then((value) => {
        this.router.navigate(["tableau-de-bord"]);
      })
      .catch((err) => {
        console.log("erreur", err);
        this.showError();
      });
  }

  async logOutResto() {
    this.router.navigate(["login"]);
    return await signOut(this.auth);
  }
}
